import { BodyText } from "../BodyText";
import { H2 } from "../H2";
import { LinkWithIcon } from "./LinkWithIcon";

export const Infomercial = ({
  title,
  subtitle,
  src,
  to,
  type,
}: {
  title: string;
  subtitle: string;
  src: string;
  to: string;
  type: "ImageText" | "TextImage";
}) => {
  return (
    <div className="grid grid-cols-1 md:mx-[175px] md:grid-cols-2 md:gap-6">
      <img
        src={src}
        alt={title}
        className={`${type === "ImageText" ? "" : "md:order-last"}`}
        loading="lazy"
      />
      <div className="mx-6 flex flex-col items-start justify-center">
        <div className="mt-12 md:mt-0">
          <H2>{title}</H2>
        </div>
        <div className="mt-[30px] md:mt-2">
          <BodyText>{subtitle}</BodyText>
        </div>
        <div className="mt-6">
          <LinkWithIcon title="Sjá meira" to={to} textColor="black" />
        </div>
      </div>
    </div>
  );
};
