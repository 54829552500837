import { Link } from "@remix-run/react";
import { LinkWithIcon } from "./LinkWithIcon";
import { formatPrice } from "~/utils/strings";
import { H2 } from "../H2";
import { H4 } from "../H4";
import { H5 } from "../H5";

export const HeroProduct = ({
  src,
  title,
  subtitle,
  description,
  price,
  link,
}: {
  src: string;
  title: string;
  subtitle: string;
  description: string;
  price: string | number;
  link: {
    url: string;
    title: string;
  };
}) => {
  return (
    <div className="grid w-full grid-cols-1 flex-col-reverse px-6 md:grid-cols-2 md:px-16">
      <section className="h-[430px]">
        <Link to={link.url} prefetch="viewport">
          <img
            src={src}
            className="h-full w-full rounded-md object-cover"
            alt={title}
          ></img>
        </Link>
      </section>
      <div className="flex flex-col items-start justify-start md:ml-[160px] md:items-start md:justify-center">
        <div className="mb-6 mt-6 md:mt-0">
          <H2>{title}</H2>
        </div>
        <div className="mb-6 flex flex-row items-center gap-6 text-xl font-bold">
          <H4>{subtitle}</H4>
          <H4>{formatPrice(Number(price))}</H4>
        </div>
        <H5>{description}</H5>
        <div className="mt-6">
          <LinkWithIcon to={link.url} title={link.title} textColor="black" />
        </div>
      </div>
    </div>
  );
};
