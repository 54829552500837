import { Link } from "@remix-run/react";
import { FilledLinkWithIcon } from "./FilledLinkWithIcon";
import { H4 } from "../H4";

export const HalfWidthImage = ({
  src,
  title,
  to,
  linkColor,
}: {
  src: string;
  title: string;
  to: string;
  linkColor: string;
}) => {
  return (
    <div className="group relative h-[660px]">
      <div className="invisible absolute top-6 mx-auto flex w-full flex-row items-center justify-center group-hover:visible">
        <H4 className="text-white">{title}</H4>
      </div>
      <div className="invisible absolute top-[50%] mx-auto flex w-full flex-row items-center justify-center group-hover:visible">
        <FilledLinkWithIcon
          to={to}
          title="Kaupa"
          textColor="black"
          bgColor={linkColor}
        />
      </div>
      <Link to={to} prefetch="viewport">
        <img
          src={src}
          alt={title}
          className="h-full w-full object-cover"
          loading="lazy"
        />
      </Link>
    </div>
  );
};
