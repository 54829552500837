import { LinkWithIcon } from "./LinkWithIcon";
import { H1 } from "../H1";

export const Hero = ({
  src,
  title,
  subtitle,
  link,
}: {
  src: string;
  title: string;
  subtitle: string;
  link: {
    url: string;
    title: string;
  };
}) => {
  return (
    <div className="grid w-full grid-cols-1 md:grid-cols-2">
      <div className="mx-6 flex flex-col items-start justify-center md:mx-16">
        <div className="mb-[46px] w-[80%]">
          <H1>{title}</H1>
        </div>
        <div className="text-base font-normal">{subtitle}</div>
        <div className="mb-8 mt-6">
          <LinkWithIcon to={link.url} title={link.title} textColor="black" />
        </div>
      </div>
      <section className="mx-0  h-screen md:mx-16">
        <img
          src={src}
          className="mx-0 h-full w-full rounded-md object-cover"
          alt={title}
        ></img>
      </section>
    </div>
  );
};
