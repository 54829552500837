import type { ReactNode } from "react";

export const H4 = ({
  children,
  className = "text-[#0E0F0F]",
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <h1 className={`text-[20px] font-bold leading-[22px] ${className}`}>
      {children}
    </h1>
  );
};
