export const HaloEffect = () => {
  return (
    <svg
      height="473"
      viewBox="0 0 1440 473"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: "100%" }}
    >
      <g filter="url(#filter0_f_3391_848)">
        <ellipse
          cx="725"
          cy="-619"
          rx="892"
          ry="892"
          transform="rotate(-180 725 -619)"
          fill="#FE74BE"
        />
      </g>
      <g filter="url(#filter1_f_3391_848)">
        <circle
          cx="713.447"
          cy="-633.228"
          r="735.97"
          transform="rotate(-180 713.447 -633.228)"
          fill="#FF3636"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_3391_848"
          x="-367"
          y="-1711"
          width="2184"
          height="2184"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_3391_848"
          />
        </filter>
        <filter
          id="filter1_f_3391_848"
          x="-222.523"
          y="-1569.2"
          width="1871.94"
          height="1871.94"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="100"
            result="effect1_foregroundBlur_3391_848"
          />
        </filter>
      </defs>
    </svg>
  );
};
