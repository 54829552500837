import type { LoaderFunctionArgs } from "@remix-run/cloudflare";
import { json } from "@remix-run/cloudflare";
import { MetaFunction, useLoaderData } from "@remix-run/react";
import { Hero } from "~/components/frontpage/Hero";
import type { DBProductInfo } from "../../../../types/ProductInfo";
import { getLandingPage, getProductsByIds } from "./queries";
import { HeroProduct } from "~/components/frontpage/HeroProduct";
import { FullScreenBorder } from "~/components/frontpage/FullScreenBorder";
import { FullWidthImage } from "~/components/frontpage/FullWidthImage";
import { HalfWidthImage } from "~/components/frontpage/HalfWidthImage";
import { Infomercial } from "~/components/frontpage/Infomericial";
import { LandingPage } from "../../../../types/LandingPage";
import { GetExtraLargeUrl, GetLargeUrl } from "~/utils/images";
import { ProductRow } from "~/components/frontpage/ProductRow";
import { HaloEffect } from "~/components/HaloEffect";

export const meta: MetaFunction = () => {
  return [
    { title: "Nola - Beauty And Bullsh*t Don't Mix" },
    { name: "theme-color", content: "#E3DACC" },
    { name: "description", content: "Nola - Beauty And Bullsh*t Don't Mix" },
  ];
};

export const loader = async ({ context }: LoaderFunctionArgs) => {
  const landingPage = await getLandingPage(context.cloudflare.env.LANDING_PAGE);
  if (!landingPage)
    return json({ products: [], newProducts: [], landingPage: {} });

  const products = await getProductsByIds(
    context.cloudflare.env.PRODUCT_INFO,
    landingPage.productCarousel?.productIds || [],
  );
  return json({
    products: products.filter((p) => p.hidden !== true),
    landingPage,
  });
};

export default function Index() {
  const data = useLoaderData<{
    products: Array<DBProductInfo>;
    landingPage: Partial<LandingPage>;
  }>();

  if (!data.landingPage) return null;

  return (
    <div className="flex w-full flex-col bg-[#E3DACC]">
      <div
        className={
          "absolute left-0 right-0 top-[-160px] overflow-clip lg:top-0"
        }
      >
        <HaloEffect />
      </div>

      <div className="mt-20">
        <Hero
          src={GetLargeUrl(data.landingPage.CTA?.image || "")}
          title={data.landingPage.CTA?.title || ""}
          subtitle={data.landingPage.CTA?.description || ""}
          link={{
            url: data.landingPage.CTA?.link.url || "/shop",
            title: data.landingPage.CTA?.link.title || "Versla",
          }}
        />
        <div className="h-[120px]"></div>

        <HeroProduct
          src={GetLargeUrl(data.landingPage.brandPromotion?.image || "")}
          title={data.landingPage.brandPromotion?.title || ""}
          subtitle={data.landingPage.brandPromotion?.productName || ""}
          description={data.landingPage.brandPromotion?.description || ""}
          price={data.landingPage.brandPromotion?.price || ""}
          link={{
            url: data.landingPage.brandPromotion?.link.url || "/shop",
            title: data.landingPage.brandPromotion?.link.title || "Versla",
          }}
        />
      </div>
      <div className="h-[112px] md:h-[120px]"></div>
      {data.landingPage.rotatingMessage?.enabled &&
        data.landingPage.rotatingMessage.message !== "" && (
          <FullScreenBorder
            text={data.landingPage.rotatingMessage.message}
            type="sand"
          />
        )}
      <FullWidthImage
        src={GetExtraLargeUrl(data.landingPage.fullWidthProduct?.image || "")}
        title={data.landingPage.fullWidthProduct?.title || ""}
        to={data.landingPage.fullWidthProduct?.link.url || "/shop"}
      />
      <div className="grid grid-cols-1 flex-col-reverse md:grid-cols-2">
        <HalfWidthImage
          src={GetLargeUrl(data.landingPage.leftCategory?.image || "")}
          title={data.landingPage.leftCategory?.title || ""}
          to={data.landingPage.leftCategory?.link.url || "/shop"}
          linkColor="#EFD4D7" //TODO: should be configurable
        />
        <HalfWidthImage
          src={GetLargeUrl(data.landingPage.rightCategory?.image || "")}
          title={data.landingPage.rightCategory?.title || ""}
          to={data.landingPage.rightCategory?.link.url || "/shop"}
          linkColor="#E3DACC" //TODO: should be configurable
        />
      </div>
      <div className="h-[112px] md:h-[120px]"></div>

      <div className="mr-0 md:mr-10">
        <ProductRow
          title={data.landingPage.productCarousel?.title || "Nýtt"}
          products={data.products}
        />
      </div>
      <div className="mt-[112px] md:mt-[121px]">
        <Infomercial
          title={data.landingPage.topInfoSection?.title || ""}
          subtitle={data.landingPage.topInfoSection?.description || ""}
          src={GetLargeUrl(data.landingPage.topInfoSection?.image || "")}
          to={data.landingPage.topInfoSection?.link.url || "/shop"}
          type="ImageText"
        />
      </div>
      <div className="mt-[114px]">
        <Infomercial
          title={data.landingPage.bottomInfoSection?.title || ""}
          subtitle={data.landingPage.bottomInfoSection?.description || ""}
          src={GetLargeUrl(data.landingPage.bottomInfoSection?.image || "")}
          to={data.landingPage.bottomInfoSection?.link.url || "/shop"}
          type="TextImage"
        />
      </div>
    </div>
  );
}
