import { Link } from "@remix-run/react";
import { LinkTo } from "~/assets/icons/LinkTo";

export const FilledLinkWithIcon = ({
  to,
  title,
  textColor,
  bgColor,
  size = "large",
}: {
  to: string;
  title: string;
  textColor: "white" | "black";
  size?: "large" | "small";
  bgColor: string;
}) => {
  return (
    <Link
      style={{ color: textColor }}
      to={to}
      className={`font-medium ${
        size === "large" ? "text-xl" : "text-sm"
      } flex flex-row items-center gap-2 px-[6px] w-fit bg-[${bgColor}]`}
    >
      {title}
      <LinkTo fill={textColor} />
    </Link>
  );
};
